import { createApp } from "vue";
import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate";
import VueCookies from 'vue3-cookies'
import App from "./App.vue";
import router from "./router";
import firebase from "firebase/app";
import 'bootstrap'

var firebaseConfig = {
    apiKey: "AIzaSyBxDlFSnbVuOdSdNlEo6abvOZUoW-o85qI",
    authDomain: "onboarding-web-7a1e4.firebaseapp.com",
    projectId: "onboarding-web-7a1e4",
    storageBucket: "onboarding-web-7a1e4.appspot.com",
    messagingSenderId: "957085694306",
    appId: "1:957085694306:web:06e812219c2777ac9dd43b",
    measurementId: "G-XG8FH1XP32"
};

const store = createStore({
    plugins: [createPersistedState()],
    state: {
        user: null
    },
    getters: {
        getUser: state => {
            return state.user;
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        }
    },
    actions: {
        setUser(context, user) {
            context.commit('setUser', user);
        },
        // signIn() {
        //     let provider = new firebase.auth.GoogleAuthProvider();
        //     firebase.auth().signInWithPopup(provider).then(function (result) {
        //         console.debug(result);
        //     })
        // },
        signOut() {
            console.debug("store signout")
            firebase.auth().signOut();
            router.push("/");
        }
    }
})

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().onAuthStateChanged(function (user) {
    console.debug("onAuthStateChanged");
    if (user) {
        // Don't store the object, just the content
        var tmpUser = JSON.stringify(user);
        var storeUser = JSON.parse(tmpUser)
        console.debug("setUser for: " + user.email);
        store.dispatch('setUser', storeUser);
    } else {
        console.debug("setUser to null");
        store.dispatch('setUser', null);
    }
});
createApp(App).use(VueCookies).use(store).use(router).mount("#app");
