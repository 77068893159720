<template>
  <HelloWorld msg="Let's get you onboarded!" />
</template>

<script>
import HelloWorld from '../components/HelloWorld.vue'
export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  props:{},
  methods:{}
}
</script>

<style>

</style>