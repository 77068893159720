import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase/app"
import "firebase/auth"

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
  },
  {
    path: "/reset-password",
    name: "account_reset",
    component: () =>
      import(/* webpackChunkName: "ResetPassword" */ "../views/ResetPassword.vue"),
  },
  {
    path: "/account/profile",
    name: "account_profile",
    component: () =>
      import(/* webpackChunkName: "AccountProfile" */ "../views/AccountProfile.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      authRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        next({
          path: '/login'
        })
      } else {
        next()
      }
    })

  } else {
    next()
  }
})

export default router;

